<template>
  <div class="container-video-mcp">
    <section class="cover-video" :class="{'loop-video': bgLoop}" v-if="!regularPlayer">
      <div class="video_jw">
        <div ref="videoPlaceholder" @click="showVideo()" class="placeholder">
          <div class="icon-play">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#fff" class="bi bi-play-circle" viewBox="0 0 16 16">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
              <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445z"/>
            </svg>
          </div>
          <img v-if="thumb" width="100%" :src="thumb">
        </div>
        <div class="content-player">
          <div :id="videoUniqId"></div>
        </div>
      </div>
   </section>
    <section class="cover-video" :class="{'loop-video': bgLoop}" v-if="regularPlayer">
      <video ref="video_player_regular" autoplay muted loop>
        <source :src="videoId" type="video/mp4">        
        Sorry, your browser doesn't support embedded videos.
    </video>
   </section>
  </div>
</template>

<style lang="scss">
  .container-video-mcp {
    width: 100%;
    overflow: hidden;
    z-index: 1;

    .cover-video {
      background: #000;

      video {
        opacity: 0.8;
        object-fit: cover;
        width: 100%;
      }
    }

    .fmg-video-sdk .fmg-rating-overlay {
      display: none;
    }

    .jw-display-controls {
      display: inline-block;
      width: 100vw;
      height: 100vh;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .icon-play {
      position: absolute;
      width: 100%;
      text-align: left;
      padding: 10px;
      z-index: 9;
      cursor: pointer;
      
      svg {
        width: 10%;
        height: 10%;
        cursor: pointer;

        path {
          cursor: pointer;
        }
      }
    }

    .placeholder {
      position: absolute;
      width: 100%;
      transition: opacity 0.5s ease-in-out;
      z-index: 99;
      opacity: 1;
      cursor: pointer;
      display: flex;
      height: 100%;

      &:hover {
        .icon-play {
          opacity: 0.5;
        }
      }

      img {
        object-fit: cover;
        height: 100%;
      }
    }
  }

</style>

<script>

  export  default {
    name: 'VidepMcpViewer',
    props: {
      playVideo: {
        type: Boolean,
        default: false
      },
      videoUniqId: {
        type: String,
        default: ``
      },
      mcpId: {
        type: String,
        default: ``
      },
      bgLoop: {
        type: Boolean,
        default: false
      },
      regularPlayer: {
        type: Boolean,
        default: false
      },
      thumb:  {
        type: String,
        default: ``
      },
    },
    data() {
      return {
        videoObject: {}
      }
    },
    mounted() {
      this.renderVideo(false)
    },
    computed:{
      videoId() {
        const id = this.mcpId.split(';')
        if(id[0])
          return id[0]
        return this.mcpId
      },
      placeholder() {
        const id = this.mcpId.split(';')
        if(id[1])
          return id[1]
        return false
      }
    },
    watch: {
      videoId() {
        if(!this.regularPlayer)
          this.showVideo()
        else
          this.showLoop()
      }
    },
    methods: {
      showPlaceholder() {
        this.videoObject.pause()
        this.$refs['videoPlaceholder'].style.display = 'block'
        setTimeout(() => {
          this.$refs['videoPlaceholder'].style.opacity = 1
        }, 100)
      },
      PlayVideo() {
        this.$refs['videoPlaceholder'].style.opacity = 0
        setTimeout(() => {
          this.$refs['videoPlaceholder'].style.display = 'none'
          this.videoObject.play()
        }, 700)
      },
      showLoop() {
        this.$refs['video_player_regular'].load()
      },
      showVideo() {

        this.videoObject =  window.FMG.getJWPlayerInstance(this.videoUniqId)

        setTimeout(() => {
          this.$refs['videoPlaceholder'].style.opacity = 0
        }, 100)
        setTimeout(() => {
          this.$refs['videoPlaceholder'].style.display = `none`
        }, 150)
        setTimeout(() => {
          this.videoObject.play()
        }, 200)

      },
      renderVideo(autoplay) {
        window.FMG.playVOD({
          mcpId: this.videoId,
          nodeId: this.videoUniqId,
          autoplay: autoplay, 
          muteAdsOnAutoplay: true,
          forceHLS: true,
          enableRating: true,
          isPrebidEnabled: true,
          isVmap: true,
          repeat: false,
          dfp: {
            prefix: 'rd.',
            advalue: 'univision_section_noticias/inicio',
            sz: '640x480'
          }
        })
      }
    }
  }

</script>
